<template>
  <div class="MiniPGoods content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="23">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="线上名称">
              <el-input
                v-model="OnlineName"
                @clear="handleSearch"
                clearable
                placeholder="输入商品线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="线下名称">
              <el-input
                v-model="OfflineName"
                @clear="handleSearch"
                clearable
                placeholder="输入商品线下名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select
                @change="handleSearch"
                v-model="GoodsType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in GoodType"
                  :key="index"
                  :label="item.Name"
                  :value="item.GoodsTypeID"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="线上分类">
              <el-cascader
                @change="handleSearch"
                v-model="CategoryID"
                clearable
                :options="GoodsCategoryValid"
                :props="{ expandTrigger: 'hover' }"
              ></el-cascader>
              <!-- <el-select
                @change="handleSearch"
                v-model="CategoryID"
                @clear="handleSearch"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" :value="0"></el-option>
                <el-option label="产品" :value="1"></el-option>
                <el-option label="项目" :value="2"></el-option>
                <el-option label="时效卡" :value="3"></el-option>
                <el-option label="通用次卡" :value="4"></el-option>
                <el-option label="储值卡" :value="5"></el-option>
                <el-option label="套餐卡" :value="6"></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="Active"
                @change="handleSearch"
                placeholder="请选择"
                clearable
              >
                <el-option label="上架" :value="true"></el-option>
                <el-option label="下架" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="1" class="text_right">
          <el-button type="primary" size="small" @click="newGoods"
            >新增商品</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table
        size="small"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="主图缩略">
          <template slot-scope="scope">
            <el-image
              style="width: 35px; height: 35px"
              :src="scope.row.Image[0].ImgURL"
              fit="fit"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="OnlineName" label="线上别名"></el-table-column>
        <el-table-column prop="Price" label="售价（元）"></el-table-column>
        <el-table-column prop="CategoryName" label="线上分类"></el-table-column>
        <el-table-column prop="Sequence" label="线上排序"></el-table-column>
        <el-table-column
          prop="GoodsTypeName"
          label="线下类型"
        ></el-table-column>
        <el-table-column prop="OfflineName" label="线下名称"></el-table-column>
        <el-table-column
          prop="JobName"
          label="状态"
          :formatter="formatStatus"
        ></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="toEditDetails(scope.row, 'edit')"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="toEditDetails(scope.row, 'copy')"
              v-prevent-click
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pad_10 dis_flex flex_x_between" v-if="paginations.total > 0">
      <el-dropdown size="small" @command="handleCommand">
        <el-button type="primary" size="small" v-prevent-click>
          批量设置
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu trigger="click" slot="dropdown">
          <el-dropdown-item command="state">状态</el-dropdown-item>
          <el-dropdown-item command="onlineType">线上分类</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-pagination
        background
        :current-page.sync="paginations.page"
        :page-size="paginations.page_size"
        :page-sizes="[1, , 10, 15, 20, 30, 40, 50]"
        :layout="paginations.layout"
        :total="paginations.total"
      ></el-pagination>
    </div>
    <!-- 新增商品 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="dialogClose('fromData')"
    >
      <el-tabs v-model="activeIndex">
        <el-tab-pane label="基本信息" value="0">
          <el-form
            ref="fromData"
            :rules="rules"
            :model="fromData"
            label-width="80px"
            size="small"
          >
            <!-- <el-form-item > -->
            <div class="dis_flex flex_y_center">
              <div>
                <el-form-item label="选择商品" prop="GoodsTypeID">
                  <el-select
                    v-model="fromData.GoodsTypeID"
                    @change="getOfflineGoods"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in GoodType"
                      :key="index"
                      :label="item.Name"
                      :value="item.GoodsTypeID"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="marlt_20">
                <el-form-item prop="OfflineGoodsID">
                  <el-select
                    placeholder="请选择"
                    filterable
                    v-model="fromData.OfflineGoodsID"
                  >
                    <el-option
                      v-for="(item, index) in OfflineGoods"
                      :key="index"
                      :label="item.Name"
                      :value="item.ID"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <!-- </el-form-item> -->
            <el-form-item label="线上名称" prop="Name">
              <el-input class="onLineInput" v-model="fromData.Name"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="7">
                <el-form-item label="线上售价" prop="Price">
                  <el-input
                    class="otherInput"
                    v-model="fromData.Price"
                    v-enter-number2
                    type="number"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="线上分类" prop="CategoryID">
                  <el-cascader
                    v-model="fromData.CategoryID"
                    :options="GoodsCategoryValid"
                    :props="{ expandTrigger: 'hover' }"
                  ></el-cascader>
                  <!-- <el-select
                    placeholder="请选择"
                    filterable
                    v-model="fromData.CategoryID"
                  >
                    <el-option
                      v-for="(item, index) in GoodsCategoryValid"
                      :key="index"
                      label="养生"
                      value="0"
                    ></el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="7">
                <el-form-item label="线上排序" prop="Sequence">
                  <el-input v-model="fromData.Sequence"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="初始销量" prop="Quantity">
                  <el-input v-model="fromData.Quantity"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="是否上架" required>
              <el-radio-group v-model="fromData.Active">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品主图" required prop="Image">
              <div class="color_999">最多上传5张，建议尺寸800px*800px</div>

              <el-upload
                action="#"
                :limit="5"
                list-type="picture-card"
                multiple
                :file-list="Images"
                :on-change="uploadImg"
                :on-exceed="imgNumExceed"
                :before-upload="beforeAvatarUpload"
                :http-request="updateCustomerUploadImage"
              >
                <i
                  slot="default"
                  class="el-icon-camera-solid"
                  style="font-size: 40px; color: #999"
                ></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    style="width: 100px; height: 100px"
                    :src="file.url"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file, index)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="商品简介" prop="Description">
              <el-input
                style="width: 95%"
                type="textarea"
                v-model="fromData.Description"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品详情" prop="content">
              <quill-editor
                ref="myTextEditor"
                v-model="fromData.Detail"
                :options="editorOption"
                style="width: 95%; height: 300px"
              ></quill-editor>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="上架网店" value="1">
          <el-tree
            class="padtp_10"
            show-checkbox
            :expand-on-click-node="false"
            node-key="ID"
            :data="entityList"
            :default-expanded-keys="[1]"
            :props="defaultProps"
          >
            <span slot-scope="{ node, data }">
              <span class="font_14">{{ data.EntityName }}</span>
              <el-tag
                class="marlt_5"
                type="info"
                size="mini"
                v-if="data.IsStore"
                >门店</el-tag
              >
              <el-tag
                class="marlt_5"
                type="info"
                size="mini"
                v-if="data.IsWarehouse"
                >仓库</el-tag
              >
            </span>
          </el-tree>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogconfirm('fromData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 批量设置 -->
    <el-dialog
      :title="batchDialogTitle"
      :visible.sync="batchDialogVisible"
      width="35%"
      @closed="dialogClose()"
    >
      <div v-if="batchDialogTitle == '状态'" class="groupBox">
        <label for=""><span style="color: red">*</span>状态</label>
        <el-radio-group class="marlt_20" v-model="batchState">
          <el-radio :label="true">上架</el-radio>
          <el-radio :label="false">下架</el-radio>
        </el-radio-group>
      </div>
      <div v-else class="groupBox">
        <span><span style="color: red">*</span>线上分类</span>
        <el-cascader
          v-model="batchCategoryID"
          clearable
          :options="GoodsCategoryValid"
          :props="{ expandTrigger: 'hover' }"
        ></el-cascader>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="batchDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="batchDialogconfirm()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/EShopManage/EShopGoods";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "MiniPGoods",
  /**  引入的组件  */
  components: {
    quillEditor,
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      OnlineName: "", //线上输入框值
      OfflineName: "", //线下输入框值
      CategoryID: "", //线上分类
      Active: "", //状态
      GoodsType: "", //商品分类
      PageNum: 1, //页码
      tableData: [], //表格数据

      paginations: {
        total: 2,
        layout: "sizes, prev, pager, next, jumper, ->, total, slot",
        page_size: 1,
        page: 1,
      },

      activeIndex: "0", //新增商品弹出层tab索引
      dialogTitle: "",
      dialogVisible: false,
      batch: [], //批量
      batchState: true,
      batchCategoryID: "",
      batchDialogTitle: "",
      batchDialogVisible: false,

      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      entityList: [
        {
          EntityName: "苏州哲联信息科技有限公司",
          IsStore: false,
          IsWarehouse: false,
          ID: 0,
          Child: [
            {
              EntityName: "珠江店",
              IsStore: true,
              IsWarehouse: false,
              ID: 1,
            },
            {
              EntityName: "万达店",
              IsStore: true,
              IsWarehouse: true,
              ID: 2,
            },
          ],
        },
      ],
      editorOption: {},
      GoodType: [], //商品分类
      OfflineGoods: [], //分类下商品
      GoodsCategoryValid: [], //线上分类
      fromData: {
        GoodsTypeID: "", //商品分类
        OfflineGoodsID: "", //分类下商品
        Name: "", //线上名称
        Price: "", //价格
        CategoryID: "", //线上分类
        Sequence: "", //排序
        Quantity: "", //销量
        Active: true, //状态
        Description: "", //简介
        Detail: "", //详情
        Image: [], //图片
      },
      Images: [],
      subType: "",
      rules: {
        GoodsTypeID: [
          { required: true, message: "请输入商品分类", trigger: "blur" },
        ],
        OfflineGoodsID: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        Name: [
          { required: true, message: "请输入线上名称", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        Price: [{ required: true, message: "请输入线上售价", trigger: "blur" }],
        CategoryID: [
          { required: true, message: "请输入线上分类", trigger: "blur" },
        ],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    // 获取网店商品列表
    getGoodAll() {
      const params = {
        OfflineName: this.OfflineName,
        GoodsType: this.GoodsType,
        OnlineName: this.OnlineName,
        CategoryID: this.CategoryID ? this.CategoryID[1] : "",
        Active: this.Active,
        PageNum: this.PageNum,
      };
      API.GoodAll(params).then((res) => {
        if (res.StateCode == 200) {
          this.tableData = res.List;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品类型
    getGoodType() {
      API.GoodType().then((res) => {
        if (res.StateCode == 200) {
          this.GoodType = res.Data;
          this.$forceUpdate();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 类型下的商品
    getOfflineGoods() {
      const params = { GoodsType: Number(this.fromData.GoodsTypeID) };
      API.offlineGoods(params).then((res) => {
        if (res.StateCode == 200) {
          this.OfflineGoods = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 线上分类 - 排除没有子类的分类
    getAppletGoodsCategoryValid() {
      API.AppletGoodsCategoryValid().then((res) => {
        if (res.StateCode == 200) {
          const arr = [];
          res.Data.forEach((res) => {
            const obj = {
              label: res.Name,
              value: res.CategoryID,
              children: [],
            };
            if (res.Child && res.Child.length) {
              res.Child.forEach((res) => {
                obj.children.push({
                  label: res.Name,
                  value: res.CategoryID,
                });
              });
            }

            arr.push(obj);
          });
          this.GoodsCategoryValid = arr;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 搜索
    handleSearch() {
      this.getGoodAll();
    },
    // 商品状态
    formatStatus(row) {
      return row.Active ? "上架" : "下架";
    },
    // 重置新增商品弹出层表单
    dialogClose(name) {
      if (name) {
        this.$refs[name].resetFields();
        this.fromData = {
          GoodsTypeID: "", //商品分类
          OfflineGoodsID: "", //分类下商品
          Name: "", //线上名称
          Price: "", //价格
          CategoryID: "", //线上分类
          Sequence: "", //排序
          Quantity: "", //销量
          Active: true, //状态
          Description: "", //简介
          Detail: "", //详情
          Image: [], //图片
        };
        this.OfflineGoods = [];
        this.Images = [];
      } else {
        console.log(111);
        this.batchState = true;
        this.batchCategoryID = [];
      }
    },
    // 点击新增
    newGoods() {
      var that = this;
      that.dialogTitle = "新增商品";
      that.dialogVisible = true;
      that.subType = "new";
    },
    // 点击编辑/复制
    toEditDetails(row, type) {
      var that = this;
      that.subType = type;
      if (type == "copy") {
        that.dialogTitle = "复制商品";
      } else {
        that.dialogTitle = "编辑商品";
      }

      that.dialogVisible = true;
      console.log(row);
      row.Image.forEach((item) => {
        that.Images.push({ url: item.ImgURL });
      });
      const params = {
        Active: row.Active,
        CategoryID: row.CategoryID,
        CategoryName: row.CategoryName,
        Description: row.Description,
        Detail: row.Detail,
        Entitys: row.Entitys,
        GoodsID: row.GoodsID,
        GoodsTypeID: row.GoodsTypeID,
        GoodsTypeName: row.GoodsTypeName,
        Image: row.Image,
        OfflineGoodsID: row.OfflineGoodsID,
        OfflineName: row.OfflineName,
        Name: row.OnlineName,
        Price: row.Price,
        Quantity: row.Quantity,
        Sequence: row.Sequence,
      };
      that.fromData = params;
      // 类型下的商品
      that.getOfflineGoods();
    },
    updateCustomerUploadImage(base64) {
      let that = this;
      let params = {
        ImgURL: base64,
      };
      API.AddImage(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.fromData.Image.push({
              ImgURL: res.Message,
              ImgType: 1,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {});
    },
    beforeAvatarUpload(file) {
      let that = this;
      let isFileType = false;
      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        isFileType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isFileType) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
        return false;
        console.log(11);
      }
      console.log(11);
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.updateCustomerUploadImage(base64);
      };
      return false;
    },
    // 上传图片
    uploadImg(file, files) {
      this.Images.push(file);
    },
    // 删除图片
    handleRemove(file) {
      console.log(file);
      const index = this.Images.findIndex((item) => item.url == file.url);
      this.Images.splice(index, 1);
      this.fromData.Image.splice(index, 1);
    },
    // 上传图片数量超出
    imgNumExceed(e) {
      this.$message.error({
        message: "最多上传5张",
        duration: 2000,
      });
    },
    // 保存-新增
    clickSaveCreate() {
      const that = this;
      let params = JSON.parse(JSON.stringify(that.fromData));
      if (Array.isArray(params.CategoryID)) {
        params.CategoryID = params.CategoryID[1];
      }
      if (params.hasOwnProperty("CategoryName")) {
        that.$delete(params, "CategoryName");
      }
      if (params.hasOwnProperty("GoodsTypeName")) {
        that.$delete(params, "GoodsTypeName");
      }
      if (params.hasOwnProperty("OfflineName")) {
        that.$delete(params, "OfflineName");
      }
      if (params.hasOwnProperty("GoodsID")) {
        that.$delete(params, "GoodsID");
      }

      API.Create(params).then((res) => {
        if (res.StateCode == 200) {
          that.dialogVisible = false;
          that.handleSearch();
          that.$message.success({
            message: "添加成功",
            duration: 2000,
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 保存-编辑
    clickSavaUpdate() {
      const that = this;
      const arr = [];

      const params = {
        GoodsID: this.fromData.GoodsID,
        GoodsTypeID: this.fromData.GoodsTypeID,
        OfflineGoodsID: this.fromData.OfflineGoodsID,
        Name: this.fromData.Name,
        Price: this.fromData.Price,
        CategoryID: this.fromData.CategoryID,
        Description: this.fromData.Description,
        Detail: this.fromData.Detail,
        Active: this.fromData.Active,
        Quantity: this.fromData.Quantity,
        Sequence: this.fromData.Sequence,
        Image: this.fromData.Image,
      };
      if (Array.isArray(params.CategoryID)) {
        params.CategoryID = params.CategoryID[1];
      }
      API.Update(params).then((res) => {
        if (res.StateCode == 200) {
          that.dialogVisible = false;
          that.handleSearch();
          that.$message.success({
            message: "添加成功",
            duration: 2000,
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 保存
    dialogconfirm(formName, type) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          if (that.subType == "new" || that.subType == "copy") {
            that.clickSaveCreate();
          } else {
            that.clickSavaUpdate();
          }
        }
      });
    },

    // 选中状态
    handleSelectionChange(e) {
      const that = this;
      that.batch = e;
    },
    // 批量弹出层
    handleCommand(e) {
      const that = this;
      if (e == "state") {
        this.batchDialogTitle = "状态";
      } else {
        this.batchDialogTitle = "线上分类";
      }
      if (!that.batch.length) {
        that.$message.error({
          message: "请选择商品",
          duration: 2000,
        });
        return;
      }
      this.batchDialogVisible = true;
    },

    // 批量保存
    batchDialogconfirm() {
      const that = this;
      const arr = [];
      that.batch.forEach((item) => {
        arr.push(item.GoodsID);
      });
      // 状态
      if (that.batchDialogTitle == "状态") {
        const params = {
          Goods: arr,
          Active: this.batchState,
        };
        API.UpdateGoodsActive(params).then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "修改成功",
              duration: 2000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        });
      } else {
        const params = {
          Goods: arr,
          CategoryID: that.batchCategoryID[1],
        };
        API.UpdateGoodsCategory(params).then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "修改成功",
              duration: 2000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        });
      }
      that.batchDialogVisible = false;
      that.batch = [];
      // 搜索
      that.handleSearch();
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {
    //  富文本编辑器配置
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
      ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
      [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
      [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
      [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
      [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
      [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
      [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
      [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
      [{ font: [] }], // 字体种类-----[{ font: [] }]
      [{ align: [] }], // 对齐方式-----[{ align: [] }]
      ["clean"], // 清除文本格式-----['clean']
      ["link", "image"],
    ]; // 链接、图片、视频-----['link', 'image', 'video']

    this.editorOption = {
      modules: {
        //工具栏定义的
        toolbar: toolbarOptions,
      },
      //主题
      theme: "snow",
      placeholder: "请输入正文",
    };
  },
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    // 获取网点商品列表
    this.getGoodAll();
    // 商品分类
    this.getGoodType();
    // 线上分类
    this.getAppletGoodsCategoryValid();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style  lang="scss">
.MiniPGoods {
  .marlt_20 {
    margin-left: 20px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  .el-upload.el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 115px;
  }
  .el-input__inner {
    padding: 0 0 0 10px;
  }
  .groupBox {
    padding: 10px 0px 0px 40px;
  }

  .el_scrollbar_height {
    overflow-x: hidden !important;
  }
  .el-tabs__content {
    height: 60vh;
    overflow-y: auto;
  }
  .el-tabs__content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .el-tabs__content::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 2px;
  }
  .el-tabs__content::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 2px;
  }
  .el-tabs__content::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  .el-tabs__content::-webkit-scrollbar-corner {
    background: #f6f6f6;
  }

  .onLineInput {
    max-width: 450px !important;
  }

  .ql-snow .ql-tooltip {
    left: 200px !important;
  }
  .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "请输入链接地址:";
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "保存";
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "请输入视频地址:";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: "14px";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: "10px";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: "18px";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: "32px";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "文本";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "标题1";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "标题2";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "标题3";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "标题4";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "标题5";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "标题6";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: "标准字体";
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: "衬线字体";
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: "等宽字体";
  }
}
</style>
